/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */

import React from 'react';
import { arrayOf, oneOfType, shape } from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Helmet } from 'react-helmet';
import { Link, StaticQuery, graphql } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
// <script src="https://kit.fontawesome.com/69694914fb.js" crossorigin="anonymous"></script>
import Logo from './logo';
import '../styles/index.sass';
import '../styles/tachyons/tachyons.css';

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        datoCmsSite {
          globalSeo {
            siteName
          }
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
        datoCmsHome {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          introTextNode {
            childMarkdownRemark {
              html
            }
          }
          copyright
        }
        allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
          edges {
            node {
              profileType
              url
            }
          }
        }
      }
    `}
    render={(data) => (
      <main>
        <HelmetDatoCms
          favicon={data.datoCmsSite.faviconMetaTags}
          seo={data.datoCmsHome.seoMetaTags}
        />
        <Helmet>
          <script src="https://kit.fontawesome.com/69694914fb.js" crossOrigin="anonymous" />
        </Helmet>
        <header className="w-100 bg-blue z-1 flex items-center justify-center">
          <div className="w-100 mw8-ns center ph2 flex">
            <Link to="/" className="w4 white db no-underline pv2">
              <h1 className="ma0 lh-solid">
                <Logo type="white" />
                <div className="f7">Digital Solutions</div>
              </h1>
            </Link>
            <div className="w-100 flex justify-end items-center">
              <div className="dn-ns">
                <label htmlFor="menu" className="f2 pr2 white-50">☰</label>
              </div>
              <input type="checkbox" id="menu" className="dn" />
              <nav className="dn db-ns fixed static-ns w-100 vh-100 h-auto-ns w-auto-ns top-0 left-0 bg-blue z-max">
                <div className="w-50 center pt6 pb4 dn-ns">
                  <Logo type="white" />
                </div>
                <label htmlFor="menu" className="f2 pr2 black-50 absolute top-1 right-1 dn-ns">×</label>
                <ul className="list flex ml0 ttu f7 justify-start justify-center-l flex-column flex-row-ns flex-wrap tc items-center">
                  <li className="mb4 ph3-ns mb0-ns w-100 w-auto-ns"><Link to="/" className="no-underline white">Home</Link></li>
                  <li className="mb4 ph3-ns mb0-ns w-100 w-auto-ns">
                    <button
                      type="button"
                      className="no-underline white bn bg-transparent ttu ma0 pa0 pointer"
                      onClick={() => { scrollTo('#why'); document.querySelector('#menu').checked = false; }}
                    >
                      Why BlueTouch
                    </button>
                  </li>
                  <li className="mb4 ph3-ns mb0-ns w-100 w-auto-ns">
                    <button
                      type="button"
                      className="no-underline white bn bg-transparent ttu ma0 pa0 pointer"
                      onClick={() => { scrollTo('#services'); document.querySelector('#menu').checked = false; }}
                    >
                      Our Services
                    </button>
                  </li>
                  <li className="mb4 ph3-ns mb0-ns w-100 w-auto-ns">
                    <button
                      type="button"
                      className="no-underline white bn bg-transparent ttu ma0 pa0 pointer"
                      onClick={() => { scrollTo('#projects'); document.querySelector('#menu').checked = false; }}
                    >
                      Projects
                    </button>
                  </li>
                  <li className="mb4 ph3-ns mb0-ns w-100 w-auto-ns">
                    <button
                      type="button"
                      className="no-underline white bn bg-transparent ttu ma0 pa0 pointer"
                      onClick={() => { scrollTo('#contact'); document.querySelector('#menu').checked = false; }}
                    >
                      Contact
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
        <div>
          {children}
        </div>
        <footer>
          <div className="w-100 mw8-ns center pv3 ph3">
            <div className="flex flex-wrap">
              <div className="w-100">
                <h2 className="f4 b mb2">About BlueTouch</h2>
                <p className="lh-copy mb3">BlueTouch is a digital solutions provider based in Houston, Texas. We specialize in Software Architecture and Development, User Experience and User Interfaces, Graphic Design, Accessibility and Search Engine Optimization.</p>
              </div>
              <div className="w-100 tl pv2">
                <div className="black-40 f6">{data.datoCmsHome.copyright}</div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    )}
  />
);

TemplateWrapper.propTypes = {
  children: oneOfType([arrayOf(shape({})), shape({})]).isRequired,
};

export default TemplateWrapper;
