import React from 'react';
import { string } from 'prop-types';

const Logo = ({ type }) => {
  const blueFill = type === 'white' ? '#fff' : '#010101';
  const touchFill = type === 'white' ? '#fff' : '#4581c2';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.45 19.84" title="BlueTouch">
      <path d="M4.6,19.62H0V.22H4.18A4.62,4.62,0,0,1,7.37,1.3a3.82,3.82,0,0,1,1,1.55,5.3,5.3,0,0,1,.37,2.06v1.8a3.86,3.86,0,0,1-1.11,3A4,4,0,0,1,9.2,13.19V15a5.07,5.07,0,0,1-.36,2,3.69,3.69,0,0,1-1,1.55A4.58,4.58,0,0,1,4.6,19.62ZM3.05,8.43H4.18a1.65,1.65,0,0,0,1.14-.34,1.88,1.88,0,0,0,.42-1.38V4.91a1.82,1.82,0,0,0-.39-1.33,1.69,1.69,0,0,0-1.17-.31H3.05Zm0,8.14H4.6a1.72,1.72,0,0,0,1.16-.3A1.74,1.74,0,0,0,6.15,15V13.19a1.88,1.88,0,0,0-.41-1.38,1.6,1.6,0,0,0-1.14-.34H3.05Z" style={{ fill: blueFill }} />
      <path d="M19.07,19.62H11V.22h3.05V16.57h5Z" style={{ fill: blueFill }} />
      <path d="M25,19.84a4.54,4.54,0,0,1-3.22-1.13,4.82,4.82,0,0,1-1.38-3.63V.22h3.05V15.08a1.87,1.87,0,0,0,.38,1.38A1.7,1.7,0,0,0,25,16.8a1.64,1.64,0,0,0,1.13-.34,1.84,1.84,0,0,0,.42-1.38V.22H29.6V15.08a4.91,4.91,0,0,1-1.39,3.63A4.63,4.63,0,0,1,25,19.84Z" style={{ fill: blueFill }} />
      <path d="M34.72,3.27V8.15h3.44v3H34.72v5.37h5v3.05h-8V.22h8V3.27Z" style={{ fill: blueFill }} />
      <path d="M50,3.27h-3.1V19.62H43.87V3.27h-3.1v-3H50Z" style={{ fill: touchFill }} />
      <path d="M55.73,19.84a4.5,4.5,0,0,1-3.21-1.13,4.78,4.78,0,0,1-1.39-3.63V4.77a4.75,4.75,0,0,1,1.39-3.63,5.1,5.1,0,0,1,6.43,0,4.79,4.79,0,0,1,1.38,3.63V15.08A4.82,4.82,0,0,1,59,18.71,4.54,4.54,0,0,1,55.73,19.84Zm0-16.79a1.62,1.62,0,0,0-1.13.33,1.85,1.85,0,0,0-.42,1.39V15.08a1.84,1.84,0,0,0,.42,1.38,1.62,1.62,0,0,0,1.13.34,1.58,1.58,0,0,0,1.14-.34,1.78,1.78,0,0,0,.41-1.38V4.77a1.79,1.79,0,0,0-.41-1.39A1.58,1.58,0,0,0,55.73,3.05Z" style={{ fill: touchFill }} />
      <path d="M66.76,19.84a4.5,4.5,0,0,1-3.21-1.13,4.78,4.78,0,0,1-1.39-3.63V.22h3V15.08a1.86,1.86,0,0,0,.39,1.38,1.69,1.69,0,0,0,1.16.34,1.65,1.65,0,0,0,1.14-.34,1.84,1.84,0,0,0,.41-1.38V.22h3V15.08A4.94,4.94,0,0,1,70,18.71,4.66,4.66,0,0,1,66.76,19.84Z" style={{ fill: touchFill }} />
      <path d="M77.79,19.84a4.63,4.63,0,0,1-3.21-1.13,4.91,4.91,0,0,1-1.39-3.63V4.77a4.88,4.88,0,0,1,1.39-3.63A4.64,4.64,0,0,1,77.79,0,4.54,4.54,0,0,1,81,1.14a4.79,4.79,0,0,1,1.38,3.63V6.49h-3V4.77A1.93,1.93,0,0,0,79,3.38a1.72,1.72,0,0,0-1.17-.33,1.62,1.62,0,0,0-1.13.33,1.85,1.85,0,0,0-.42,1.39V15.08a1.84,1.84,0,0,0,.42,1.38,1.62,1.62,0,0,0,1.13.34A1.72,1.72,0,0,0,79,16.46a1.91,1.91,0,0,0,.38-1.38v-2.5h3v2.5A4.82,4.82,0,0,1,81,18.71,4.54,4.54,0,0,1,77.79,19.84Z" style={{ fill: touchFill }} />
      <path d="M93.45.22v19.4h-3V11.2H87.3v8.42h-3V.22h3V8.15h3.1V.22Z" style={{ fill: touchFill }} />
    </svg>
  );
};

Logo.defaultProps = {
  type: '',
};

Logo.propTypes = {
  type: string,
};

export default Logo;
